














import Vue from 'vue';
import {School, Student} from '~/models';

export default Vue.extend({
  name: 'ScriptUsersPage',
  data() {
    return {
      search: '',
    };
  },
  computed: {
    school(): School {
      return this.$store.state.auth.school;
    },
    students(): Student[] {
      const school = School.query().with('students.schoolClass').first();
      return school.students.filter(student => student.type === 'script');
    },
    headers(): any[] {
      return [
        {text: this.$t('label.forename'), value: 'forename'},
        {text: this.$t('label.surname'), value: 'surname'},
        {text: this.$t('label.email'), value: 'email'},
        {text: this.$t('label.schoolClass'), value: 'schoolClass.name'},
      ];
    },
  },
  methods: {
    customFilter(_a: string, search: string, student: Student): boolean {
      const lowerSearchParts = search.toLowerCase().split(' ');
      return lowerSearchParts.reduce((prev: boolean, sPart) => prev && (
          student.fullName.toLowerCase().includes(sPart) ||
          student.email.toLowerCase().includes(sPart))
          , true);
    },
  },
});
